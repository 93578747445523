import React from 'react'
import MyTimer from '../hooks/useTimer'

const QuestionTimer = ({ imageUrl, onTimerExpire }) => {
  const countDownTime = new Date()
  countDownTime.setSeconds(countDownTime.getSeconds() + 5) // 5 sec

  return (
    <>
      <div>
        <MyTimer expiryTimestamp={countDownTime} onExpire={onTimerExpire} />
      </div>

      <div className="">
        <img src={imageUrl} />
      </div>
      <p>5秒間のみ問題が表示されます。</p>
    </>
  )
}

export default QuestionTimer
