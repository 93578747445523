import React,{ useState, useEffect } from 'react'
import Modal from 'react-modal'
import MyTimer from '../hooks/useTimer'
import AnswerSelects from './AnswerSelects'

const zeroPadding = number => {
  return ( '000' + number ).slice( -3 )
}

const preloadImage = imageUrl => {
  const img = new Image()
  img.src = imageUrl
}

const selectImageKeys = (quiz) => {
  return Object.keys(quiz).filter(value => value.match(/select_image/))
}

const CalculationsTest = ({ quizList }) => {
  const [quizzes, setQuizzes] = useState(quizList)
  const [currentQuizNumber, setcurrentQuizNumber] = useState(0)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalText, setModalText] = useState('テストを提出しますか?')
  const [cancelCounter, setCancelCounter] = useState(0)
  const [answers, setAnswers] = useState([])
  const [timerIsStop, setTimerIsStop] = useState(false)

  const preloadQuizImages = targetNumber => {
    if (!quizzes[targetNumber]) { return }

    preloadImage(quizzes[targetNumber].question_image.url)
    selectImageKeys(quizzes[targetNumber]).map((key, index) => {
      if (quizzes[targetNumber][key].url) {
        preloadImage(quizzes[targetNumber][key].url)
      }
    })
  }

  useEffect(() => {
    if (currentQuizNumber === 0) {
      preloadQuizImages(currentQuizNumber)
    }

    preloadQuizImages(currentQuizNumber + 1)
  }, [currentQuizNumber])

  const expiresDate = new Date()
  expiresDate.setTime(expiresDate.getTime() + (1*24*60*60*1000)) // 1 days

  let startTime = new Date()

  const countDownTime = new Date()
  countDownTime.setSeconds(countDownTime.getSeconds() + 600) // 10 minutes timer

  const answerNext = e => {
    const answerNumber = e ? Number(e.currentTarget.dataset.value) + 1 : null

    let endTime = new Date()
    let playTime = Math.abs(endTime.getTime() - startTime.getTime()) / 1000

    setAnswers([
      ...answers, 
      { 
        id: quizzes[currentQuizNumber].id,
        quizType: quizzes[currentQuizNumber].quiz_type,
        quizNumber: currentQuizNumber + 1,
        selectAnswerNumber: answerNumber,
        playTime: playTime
      }
    ])

    const currentQuizDom = document.getElementById(`quiz-bar-${currentQuizNumber + 1}`)
    if ( currentQuizDom ) {
      currentQuizDom.classList.add('bg-test-category-color1')
    }

    if (!quizzes[currentQuizNumber + 1]) {
      answerFinish()
    } else {
      startTime = new Date()
      setcurrentQuizNumber(currentQuizNumber + 1) 
    }
  }

  const answerFinish = () => {
    setTimerIsStop(true)
    setModalIsOpen(true)
  }

  const onAnswerTimerExpire = () => {
    answerFinish()
  }

  const answerHandleChange = e => {
    answerNext(e)
  }

  const finishHandleYesClick = e => {
    if (cancelCounter >= 1) {
      location.href = '/'
    } else {
      document.getElementById('answers_json').value = JSON.stringify({ 'testCategory': 'Calculations', 'answers': answers })
      document.finishedForm.submit()
 
      //localStorage.setItem('answersJson', JSON.stringify({ 'testCategory': 'Calculations', 'answers': answers }))
      //location.href = '/tests/finisheds/new/'
    }
  }

  const finishHandleNoClick = e => {
    if (cancelCounter >= 1) {
      setCancelCounter(cancelCounter - 1)
      setModalText('テストを提出しますか?')
    } else {
      setModalText('テスト結果が消滅しますがよろしいでしょうか?')
      setCancelCounter(cancelCounter + 1)
    }
  }

  return (
    <>
      <Modal
        style={{
          overlay: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#000000a8',
            alignItems: 'center',
            zIndex: '100'
          },
          content: {
            position: 'absolute',
            width: '60%',
            margin: '15% auto',
            padding: '0',
            inset: '0',
            background: '#f7fbfc',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '20px'
          }
        }}
        isOpen={modalIsOpen}
      >
        <div className="flex h-full items-center flex-wrap">
          <div className="text-center w-full mx-auto text-xl md:text-2xl font-bold">
            {modalText}
          </div>

          <div className="lg:flex justify-around w-4/5 mx-auto items-center">
            <button className="mt-6 w-full lg:w-40 py-2 px-8 font-bold text-xl text-center bg-color08 text-white rounded-full" onClick={finishHandleYesClick}>はい</button>
            <button className="mt-6 w-full lg:w-40 py-2 px-8 font-bold text-xl text-center bg-color08 text-white rounded-full" onClick={finishHandleNoClick}>いいえ</button>
          </div>
        </div>
      </Modal>

      <div className="w-11/12 mx-auto">
        { !timerIsStop && 
          <div className="w-full container mx-auto mt-2">
            <div className="w-full bg-color01 rounded-xl py-4 px-2 lg:p-4">

              <div className="text-center font-bold flex justify-center text-xl">
                <div className="bg-color02 w-40 rounded-xl p-2 text-3xl mr-2">
                  {zeroPadding(currentQuizNumber + 1)}/{zeroPadding(quizzes.length)}
                </div>
                <div className="bg-color02 w-40 rounded-lg p-2">
                  <MyTimer expiryTimestamp={countDownTime} onExpire={onAnswerTimerExpire} timerIsStop={timerIsStop} />
                </div>
              </div>

              <ul className="flex justify-center mt-4 w-full rounded-xl overflow-hidden">
                { quizzes.map((key, index) => {
                  return (
                    <li id={`quiz-bar-${index + 1}`} key={index} className="bg-color02 w-full h-2"></li>
                  )
                })}
              </ul>

              <div className="md:flex justify-between mt-4">
                <div className="w-full md:w-3/5 h-64 md:h-auto bg-color02 flex justify-center items-center md:items-start lg:p-4 mr-4">
                  <img src={quizzes[currentQuizNumber].question_image.url} className="border-gray-300 border-2 w-auto md:w-3/5 max-h-full" />
                </div>
                <div className="w-full md:w-2/5 border-2 border-gray-300 mt-10 md:mt-0 flex justify-center items-center">
                  <AnswerSelects quiz={quizzes[currentQuizNumber]} handleChange={answerHandleChange} />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  )
}

export default CalculationsTest
