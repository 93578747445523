import React, { Fragment } from 'react'

const selectImageKeys = (quiz) => {
  return Object.keys(quiz).filter(value => value.match(/select_image/))
}

const ImageSelects = ({ quiz, handleChange }) => {
  return (
    <>
      { selectImageKeys(quiz).map((key, index) => {
        return (
          <Fragment key={index}>
            { (quiz[key].url && quiz[key].url.length) &&
              <div className="w-1/2 p-2 md:p-8 flex justify-center items-center">
                <p className="font-bold mr-2 text-xl">{index + 1}.</p>
                <div className="border-2 border-gray-300 bg-color02 flex justify-center items-center">
                  <button onClick={handleChange} data-value={index} name='select_answer'>
                    <img src={quiz[key].url} className="w-16 md:w-24" />
                  </button>
                </div>
              </div>
            }
          </Fragment>
        )
      })}
    </>
  )
}

export default ImageSelects
