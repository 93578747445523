import React, {useState} from 'react'
import Modal from 'react-modal'

const MyGiftedForm = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [modalText, setModalText] = useState('MyGiftedに入会します。よろしいですか？(決済処理を行います)')

  const handleClick = async (event) => {
    event.preventDefault()
    setModalIsOpen(true)
  }

  const handleCancelClick = async (event) => {
    event.preventDefault()
    setButtonDisabled(false)
    setModalIsOpen(false)
  }

  const handleRegisterClick = async (event) => {
    event.preventDefault()
    setModalText('登録処理中です...。このまましばらくお待ち下さい。')
    setButtonDisabled(true)

    const form = document.getElementById('membership_form')
    if(form) {
      form.submit()
    }
  }

  return (
    <>
      <Modal 
        style={{
          overlay: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#000000a8',
            alignItems: 'center',
            zIndex: '100'
          },
          content: {
            position: 'absolute',
            width: '60%',
            margin: '15% auto',
            padding: '0',
            inset: '0',
            background: '#f7fbfc',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '20px'
          }
        }}
        isOpen={modalIsOpen}
      >
        <div className="flex justify-center items-center h-full">
          <div>
            {buttonDisabled ?
              <div className="text-center w-full mx-auto text-xl md:text-2xl font-bold">
                購入処理中です...。このまましばらくお待ち下さい。
              </div>
            :
              <>
                <button className="absolute -right-0 -top-0 text-4xl font-bold bg-gray-200 p-5" onClick={handleCancelClick}>✕</button>
                <div className="text-center w-full mx-auto text-xl md:text-2xl font-bold pt-16">
                  {modalText}
                </div>

                <div className="flex h-full items-center flex-wrap pt-16">
                  <div className="lg:flex justify-around w-full mx-auto items-center">
                    <button className="mt-6 border border-black w-full lg:w-62 py-2 px-8 mr-2 text-center bg-white rounded-full" onClick={handleCancelClick}>キャンセル</button>
                    <button className="mt-6 w-full lg:w-62 py-2 px-8 text-center bg-color08 text-white rounded-full" onClick={handleRegisterClick}>MyGiftedに入会する</button>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </Modal>

      <button className="relative bg-button-color01 overflow-hidden flex items-center justify-around p-4 w-full lg:w-72 mx-auto text-base mt-12 text-center text-white block font-bold rounded-full transition duration-300" onClick={handleClick}>
        <span className="w-4/5 block z-10 underline">確認</span>
      </button>
    </>
  )
}

export default MyGiftedForm
