import React, { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'

const zeroPadding = number => {
  return ( '00' + number ).slice( -2 )
}

const MyTimer = ({ expiryTimestamp, onExpire, timerIsStop }) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire })

  useEffect(() => {
    if(timerIsStop) {
      pause()
    }
  }, [timerIsStop])

  return (
    <div style={{textAlign: 'center'}}>
      <div className="text-3xl">
        <span>{zeroPadding(minutes)}</span>:<span>{zeroPadding(seconds)}</span>
      </div>
    </div>
  )
}

export default MyTimer
