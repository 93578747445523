import React from 'react'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

import UpdateForm from './UpdateForm'

const StripeUpdateForm = ({ stripePublicKey }) => {
  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(stripePublicKey)

  const options = {
    appearance: {/*...*/},
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <UpdateForm />
    </Elements>
  )
}

export default StripeUpdateForm
