import React, {useState} from 'react'
import Modal from 'react-modal'

const TicketForm = () => {
  const planIdElement = document.getElementsByName('plan_id')
  let planIdLength = planIdElement.length
  let planId = ''
  for (let i = 0; i < planIdLength; i++){
    if (planIdElement.item(i).checked){
      planId = planIdElement.item(i).value
    }
  }

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleClick = async (event) => {
    event.preventDefault()
    setModalIsOpen(true)
  }

  const handleRegisterClick = async (event) => {
    event.preventDefault()
    setButtonDisabled(true)

    const form = document.getElementById('ticket_form')
    if(form) {
      form.submit()
    }
  }

  const handleCancelClick = async (event) => {
    event.preventDefault()
    setButtonDisabled(false)
    setModalIsOpen(false)
  }

  return (
    <>
      <Modal 
        style={{
          overlay: {
            position: 'fixed',
            display: 'flex',
            backgroundColor: '#000000a8',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '100'
          },
          content: {
            position: 'absolute',
            width: '60%',
            margin: '6% auto',
            padding: '0',
            inset: '0',
            border: 'none',
            background: '#f7fbfc',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '20px'
          }
        }}
        isOpen={modalIsOpen}
      >
        <div className="flex justify-center items-center h-full">
          <div>
            {buttonDisabled ?
              <div className="text-center w-full mx-auto text-xl md:text-2xl font-bold">
                購入処理中です...。このまましばらくお待ち下さい。
              </div>
            :
              <>
                <button className="absolute -right-0 -top-0 text-4xl font-bold bg-gray-200 p-5" onClick={handleCancelClick}>✕</button>
                <p className="text-center text-2xl md:text-4xl border-b pb-8 font-bold">チケットを購入</p>
                <div className="mt-10 flex justify-center items-center">
                  <img src={`/ticket${planId}.png`} />
                </div>

                <div className="text-center w-full mx-auto text-xl md:text-2xl font-bold pt-16">
                  チケットを購入します。よろしいですか？
                </div>

                <div className="flex h-full items-center flex-wrap pt-16">
                  <div className="lg:flex justify-around w-full mx-auto items-center">
                    <button className="mt-6 border border-black w-full lg:w-62 py-2 px-8 mr-2 text-center bg-white rounded-full" onClick={handleCancelClick}>キャンセル</button>
                    <button className="mt-6 w-full lg:w-62 py-2 px-8 text-center bg-color08 text-white rounded-full" onClick={handleRegisterClick}>購入する</button>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </Modal>

      <div className="mt-12">
        <button onClick={handleClick} disabled={buttonDisabled} className="w-64 flex items-center mx-auto justify-between block px-4 text-left py-4 px-6 font-bold bg-button-color02 rounded-full">
          <div className="z-10 underline block text-lg">確認する</div>
          <div className="z-10 flex flex-row-reverse ml-4">
            <span className="rounded-full pt-1 w-8 h-8 text-center border-black font-bold border flex items-center justify-center">→</span>
          </div>
        </button>
      </div>
    </>
  )
}

export default TicketForm
