import React,{ useState } from 'react'
import Modal from 'react-modal'
import { useCookies } from 'react-cookie'

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
  },
  content: {
    position: "absolute",
    top: "5rem",
    left: "5rem",
    right: "5rem",
    bottom: "5rem",
    borderRadius: "1rem",
    padding: "1.5rem"
  }
}

const TestLimitModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true)

  const HandleTopClick = e => {
    location.href = '/'
  }

  const HandleTestResultClick = e => {
    location.href = '/tests/results/'
  }

  const HandleTicketClick = e => {
    location.href = '/tests/tickets/new/'
  }

  return (
    <>
      <Modal 
        style={{
          overlay: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '100'
          },
          content: {
            position: 'absolute',
            width: '60%',
            margin: '15% auto',
            padding: '0',
            inset: '0',
            border: '3px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
          }
        }}
        isOpen={modalIsOpen}
      >
        <div className="flex h-full items-center flex-wrap">
          <div className="text-center w-full mx-auto text-xl md:text-2xl font-bold">
            テストチケットがありません。<br />
            テスト結果を確認するにはチケットを購入してください。
          </div>

          <div className="lg:flex justify-around w-4/5 mx-auto items-center">
            <button className="mt-6 w-full lg:w-64 py-2 px-8 font-bold text-xl text-center bg-color08 text-white rounded-full" onClick={HandleTicketClick}>チケットを購入する</button>
            <button className="mt-6 w-full lg:w-64 py-2 px-8 font-bold text-xl text-center bg-color08 text-white rounded-full" onClick={HandleTopClick}>トップに戻る</button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default TestLimitModal
