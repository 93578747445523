import React,{ useState } from 'react'
import Modal from 'react-modal'
import { useCookies } from 'react-cookie'

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
  },
  content: {
    position: "absolute",
    top: "5rem",
    left: "5rem",
    right: "5rem",
    bottom: "5rem",
    borderRadius: "1rem",
    padding: "1.5rem"
  }
}

const TestLimitModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true)

  const HandleTopClick = e => {
    location.href = '/'
  }

  const HandleTestResultClick = e => {
    location.href = '/tests/results/'
  }

  return (
    <>
      <Modal isOpen={modalIsOpen} style={modalStyle}>
        <p>
          テストを受けるにはプラン入会が必要です。<br />
          <a href="/users/plan/" class="mt-8">> プラン入会はこちら</a>
        </p>

        <div className="flex justify-around">
          <button onClick={HandleTopClick}>トップに戻る</button>
        </div>
      </Modal>
    </>
  )
}

export default TestLimitModal
