import React,{ useState, useEffect } from 'react'
import Modal from 'react-modal'
import { useCookies } from 'react-cookie'

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 50,
  },
  content: {
    position: "absolute",
    top: "1.5rem",
    left: "1.5rem",
    right: "1.5rem",
    bottom: "1.5rem",
    borderRadius: "1rem",
    padding: "1.5rem"
  }
}

const MembershipRecommendation = ({ userIq }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['membership'])
  const [modalIsOpen, setModalIsOpen] = useState(true)

  useEffect(() => {
  }, [])

  const HandleYesClick = e => {
    setCookie('membership', true, { path: '/' })
    location.href = '/tests/membership_plans/new/'
  }

  const HandleNoClick = e => {
    setModalIsOpen(false)
  }

  const HandleNeverNoClick = e => {
    setCookie('membership', true, { path: '/' })
    setModalIsOpen(false)
  }

  return (
    <>
      <Modal isOpen={modalIsOpen} style={modalStyle}>
        <p className="">
          あなたのIQは...
        </p>
        <div><b>{userIq}</b></div>
        <p>です</p>

        <p>
          おめでとうございます!<br/>
          会員制コミュニティ参加資格をGETしました！<br/>
          会費を支払えば、高IQコミュニティー「マイギフテッド」のメンバーになることができます。
        </p>

        <div className="flex justify-around">
          <button onClick={HandleYesClick}>入会する</button>
          <button onClick={HandleNoClick}>✕ 閉じる</button>
          <button onClick={HandleNeverNoClick}>次回以降表示しない</button>
        </div>
      </Modal>
    </>
  )
}

export default MembershipRecommendation
