import React from 'react'
import ImageSelects from './ImageSelects'
import NumberSelects from './NumberSelects'

const answerFormatName = (quiz) => {
  return 'image'
}

const AnswerSelects = ({ quiz, handleChange }) => {
  return (
    <div className="w-full flex items-center flex-wrap">
      { answerFormatName(quiz) === 'number' ?
        <NumberSelects quiz={quiz} handleChange={handleChange} /> :
        <ImageSelects quiz={quiz} handleChange={handleChange} /> }
    </div>
  )
}

export default AnswerSelects
