import React,{ useState, useEffect } from 'react'
import MyTimer from '../hooks/useTimer'
import AnswerSelects from './AnswerSelects'

const MemoriesAnswer = ({ quiz, countDownTime, onExpire, handleChange, timerIsStop }) => {
  return (
    <div className="w-full">
      <div>
        <div className="text-center font-bold flex justify-center text-xl">
          <div className="bg-color02 w-40 rounded-lg p-2">
            <MyTimer expiryTimestamp={countDownTime} onExpire={onExpire} timerIsStop={timerIsStop} />
          </div>
        </div>

        <p className="text-xl lg:text-3xl font-bold text-center mt-8">
          {quiz.question_text}
        </p>
      </div>

      <div className="flex justify-around mt-10 w-full lg:w-1/2 mx-auto">
        <AnswerSelects quiz={quiz} handleChange={handleChange} />
      </div>
    </div>
  )
}

export default MemoriesAnswer
