import React,{ useState } from 'react'
import Modal from 'react-modal'
import { useCookies } from 'react-cookie'

const TestTicketConfirmButton = ({ testName, testPath, noTicket }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const HandleStartClick = e => {
    if (noTicket) {
      setModalIsOpen(true)
    } else {
      location.href = testPath
    }
  }

  const HandleOkClick = e => {
    location.href = testPath
  }

  const HandleNgClick = e => {
    setModalIsOpen(false)
  }

  return (
    <>
      <Modal 
        style={{
          overlay: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#000000a8',
            alignItems: 'center',
            zIndex: '100'
          },
          content: {
            position: 'absolute',
            width: '60%',
            margin: '15% auto',
            padding: '0',
            inset: '0',
            background: '#f7fbfc',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '20px'
          }
        }}
        isOpen={modalIsOpen}
      >
        <div className="flex h-full items-center flex-wrap">
          <div className="text-center w-full mx-auto text-xl md:text-2xl font-bold">
            テストチケットがありません。<br />
            結果を確認するにはチケットを購入してください。<br />
            テストを受けますか？
          </div>

          <div className="lg:flex justify-around w-4/5 mx-auto items-center">
            <button className="mt-6 w-full lg:w-64 py-2 px-8 font-bold text-xl text-center bg-color08 text-white rounded-full" onClick={HandleOkClick}>テストを受ける</button>
            <button className="mt-6 w-full lg:w-64 py-2 px-8 font-bold text-xl text-center bg-color08 text-white rounded-full" onClick={HandleNgClick}>キャンセル</button>
          </div>
        </div>
      </Modal>

      <button onClick={HandleStartClick} className="relative bg-button-color01 overflow-hidden flex items-center justify-around p-4 w-full lg:w-72 mx-auto text-base mt-6 text-center text-white block font-bold rounded-full transition duration-300">
        <span className="w-4/5 block z-10 underline">{testName}テストを受ける</span>
        <div className="z-10 flex flex-row-reverse">
          <span className="rounded-full w-10 h-10 text-center pt-1 font-bold border flex items-center justify-center">→</span>
        </div>
      </button>
    </>
  )
}

export default TestTicketConfirmButton
