import React from 'react'

const selectNumberKeys = (quiz) => {
  return Object.keys(quiz).filter(value => value.match(/select_number/))
}

const NumberSelects = ({ quiz, handleChange }) => {
  return (
    <>
      { selectNumberKeys(quiz).map((key, index) => {
        return (
          <>
            { quiz[key] &&
              <div key={index} className="w-1/2 pb-12 flex justify-center items-center">
                <p className="font-bold mr-2 text-xl">{index + 1}.</p>
                <div>
                  <button className="bg-color02 text-3xl p-4 text-center font-bold flex justify-center items-center" onClick={handleChange} data-value={index} name='select_answer'>
                    {quiz[key]}
                  </button>
                </div>
              </div>
            }
          </>
        )
      })}
    </>
  )
}

export default NumberSelects
