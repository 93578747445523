import React,{ useState } from 'react'
import Modal from 'react-modal'
import { useCookies } from 'react-cookie'
import Logo from "images/logo03.svg"

const TestLoginButton = ({ testName, testPath }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const HandleStartClick = e => {
    setModalIsOpen(true)
  }

  const HandleOkClick = e => {
    location.href = testPath
  }

  const HandleLoginClick = e => {
    location.href = '/users/sign_in/'
  }

  const handleCancelClick = async (event) => {
    event.preventDefault()
    setModalIsOpen(false)
  }

  return (
    <>
      <Modal 
        style={{
          overlay: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#000000a8',
            alignItems: 'center',
            zIndex: '100'
          },
          content: {
            position: 'absolute',
            width: '60%',
            margin: '4% auto',
            padding: '0',
            inset: '0',
            background: '#f7fbfc',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '20px'
          }
        }}
        isOpen={modalIsOpen}
      >
        <div className="flex flex-col h-full justify-center items-center flex-wrap text-center text-xl md:text-2xl">
          <button className="absolute -right-0 -top-0 text-4xl font-bold bg-gray-200 p-5" onClick={handleCancelClick}>✕</button>

          <p className="w-full border-b-2 mt-10 border-gray-300 text-2lg pb-6 text-center">ログイン</p>

          <div className="w-full mt-4">
            <span className="text-base">既にアカウントをお持ちの方は、<br className="lg:hidden" />ログインしてください。</span><br />
            <span className="text-sm lg:text-sm text-pink-500">※ログイン後でないと、<br className="lg:hidden" />データが保存されません。</span>
          </div>

          <div className="w-64 mx-auto mt-4">
            <img src={Logo} alt="logo" className="w-1/2 mx-auto" />
          </div>

          <div className="mt-4">
            <button onClick={HandleOkClick} className="flex items-center justify-between block p-4 w-60 text-left mt-2 text-center bg-button-color02 rounded-full">
              <div className="text-sm z-10 w-3/5 block text-center">テストを受ける</div>
              <div className="z-10 flex flex-row-reverse ml-4">
                <span className="rounded-full pt-1 w-8 h-8 text-center border-black font-bold border flex items-center justify-center">→</span>
              </div>
            </button>
          </div>

          <button className="underline text-blue-500 text-sm mt-4" onClick={HandleLoginClick}>ログイン</button>
        </div>
      </Modal>

      <button onClick={HandleStartClick} className="relative bg-button-color01 overflow-hidden flex items-center justify-around p-4 w-full lg:w-72 mx-auto text-base mt-6 text-center text-white block font-bold rounded-full transition duration-300">
        <span className="w-4/5 block z-10 underline">{testName}テストを受ける</span>
        <div className="z-10 flex flex-row-reverse">
          <span className="rounded-full w-10 h-10 text-center pt-1 font-bold border flex items-center justify-center">→</span>
        </div>
      </button>
    </>
  )
}

export default TestLoginButton
