import React,{ useState, useEffect } from 'react'
import MyTimer from '../hooks/useTimer'

const MemoriesQuestion = ({ questionImageUrl, countDownTime, onExpire, timerIsStop }) => {
  return (
    <div className="w-full">
      <div>
        <div className="text-center font-bold flex justify-center text-xl">
          <div className="bg-color02 w-40 rounded-lg p-2">
            <MyTimer expiryTimestamp={countDownTime} onExpire={onExpire} timerIsStop={timerIsStop} />
          </div>
        </div>

        <p className="text-lg md:text-xl font-bold text-center mt-4">5秒間のみ問題が表示され、その後に回答選択肢が表示されます。</p>
      </div>

      <div className="w-full mt-4 flex justify-center items-center">
        <img src={questionImageUrl} className="border-2 border-gray-300" />
      </div>
    </div>
  )
}

export default MemoriesQuestion
